/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"

import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      {children}
      <footer>
        <div className="copy">
          © {new Date().getFullYear()}, Konzeptstudio
        </div>
        <div className="links">
          <ul>
            <li><Link to="/impressum">Impressum</Link></li>
            <li><Link to="/datenschutzerklarung">Datenschutzerklärung</Link></li>
          </ul> 
        </div>
      </footer>
      <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{ background: "white", color: "black", borderTop: "1px solid black"}}
          buttonStyle={{ background: "black", color: "white" }}
          declineButtonStyle={{ background: "white", color: "black", border: "1px solid black", float: "right" }}
          declineButtonText="Decline"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics">
          This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
          We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
