import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { bool, func } from "prop-types";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";

export const NavMenu = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  ul {
    display: flex;
    list-style: none;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    height: 60px;
    margin: 0;
    li {
      margin: 0;
      &:first-child {
        &:before {
          content:"";
        }
      }
     line-height: 0;
      &:before {
        content: '/';
        font-size: 12px;
        font-weight: normal;
        padding-left: 18px;
        padding-right: 18px;
      }
      a {
        &.active {
          color: black;
          font-weight: 500;
          &:after {
            content:"";
            background: black;
            border-radius: 30px;
            height: 3px;
            position: absolute;
            opacity: 1;
            bottom: -30px;
            width: 100%;
            left: 0;
            transform: scaleX(1);
            transition: transoform 2s ease;
          }
        }
        color: #666;
        text-decoration: none;
        position: relative;
        &:hover {
          color: black;
          transition: all 0.7s ease;
        }
      }
      &:last-child {
        margin-right: 3em;
      }
    }
  }

  @media (max-width: 768px) {
    .navbuger {
      display: inline;
      float: right;
      margin-top: 0.5rem;
    }
    display: block;
    height: ${({ open }) => (open ? "100vh" : "0px")};
    overflow: ${({ open }) => (open ? "hidden" : "auto")};
    left: 0;
    position: absolute;
    background-color: white;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-200%)")};
    ul {
      display: flex;
      height: 90vh;
      justify-content: center;
      flex-direction: column;
      align-items: center
      height: auto;
      background-color: white;
      li {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 50px;
        text-align: center;
        display: flex;
        &:last-child {
          margin-right: unset;
        }
        &:before {
          content:"";
          padding-left: unset;
          padding-right: unset;
        }
        a {
          width: 100%;
          text-align: center;
          position: relative;
          display: grid;
          &.active {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const Menu = ({ open, setOpen }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressMenusMenusItems {
          items {
            title
            slug
          }
        }
      }
    `}
    render={(data) => (
      <NavMenu className="menu" open={open} onClick={() => setOpen(!open)}>
        <ul>
          {data.wordpressMenusMenusItems.items.map((item) => (
              <li key={item.slug} className="nav-item">
                  <AniLink
                  cover 
                  bg="#000000"
                  duration={0.8}
                  to={`/${item.slug}`}
                  activeClassName="active"
                >
                  {item.title}
                </AniLink>
              </li>
          ))}
        </ul>
      </NavMenu>
    )}
  />
);

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Menu;
